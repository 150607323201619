// Polyfills
import 'focus-visible';
import 'element-qsa-scope';
import smoothScrollPolyfill from 'smoothscroll-polyfill';

// Plugins & frameworks
// import 'alpinejs';

import navPanel from '../../components/nav-panel/nav-panel';
import navMobile from '../../components/nav-mobile/nav-mobile';
import topBar from '../../components/top-bar/top-bar';
import lazyLoad from '../../components/lazy-load/lazy-load';
import gravityForms from '../../components/gravity-forms/gravity-forms';
import carousels from '../../blocks/carousel/carousel';
import accordion from '../../blocks/accordion/accordion';
import starRating from '../../blocks/star-rating/star-rating';
// import langSwitcher from '../../components/lang-switcher/lang-switcher';
// import tablist from '../../components/tablist/tablist';
// import scrollAnimations from '../../components/scroll-animations/scroll-animations';
import googleMap from '../../blocks/google-map/google-map';
// import postListFilters from '../../blocks/post-list-filters/post-list-filters';
// import { debounce } from 'lodash-es';

const appStart = () => {

  // Make WP-core jQuery available globally
  window.$ = window.jQuery;

  smoothScrollPolyfill.polyfill();

  navPanel.init();
  navMobile.init();
  topBar.init();
  lazyLoad.init();
  gravityForms.init();
  carousels.init();
  accordion.init();
  starRating.init();
  // langSwitcher.init();
  // tablist.init();
  // scrollAnimations.init();
  googleMap.init();
  // postListFilters.init();
};

// 'DOMContentLoaded' may fire before your script has a chance to run.
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', appStart);
} else {
  appStart();
}
